
.period-header {
  background-color: #ff6488;
  color: white;

}

.period-month {
  background-color: #ff88a4;
  color: white;
  text-align: right !important;
}

.period-subheader-editable {
  background-color: #ddfcdd;
}

.period-subheader-editable:hover {
  background-color: #b9ffb9;
  cursor: pointer !important;
}

.period-cell-true {
  border-color: #9eff9e;
  background-color: #cfffcf;
  text-align: center !important;
}

.period-cell-false {
  border-color: #dd5978;
  background-color: #d1909f;
  text-align: center !important;
}

.period-cummulative {
  background-color: #f2f1f1 !important;
  color: #cccccc !important;
}

.cashflow-offset {
  td {
    text-align: center;
    align-content: center;
  }
}

.period-header-radius-left {
  border-top-left-radius: 5px;
}

.period-header-radius-right {
  border-top-right-radius: 5px;
}

.period-header-radius-bottom-left {
  border-bottom-left-radius: 5px;
}

.period-header-radius-botton-right {
  border-bottom-right-radius: 5px !important;
}

.cashflow-indicator-ok {
  background-color: #d1ffd1 !important;
}

.cashflow-indicator-notok {
  background-color: #ffd7e0 !important;
}

.cashflow-code-stretch {
  width: 300px !important;
}

.cashflow-matrix-table {
  width: 600px !important;
}

.cashflow-matrix-projectionby {
  width: 100px !important;
  text-align: center !important;
}

.cashflow-matrix-cols {
  text-align: center !important;
  vertical-align:middle !important;
}

.cashflow-category-header {
  background-color: #eeeeee !important;
}

.cashflow-matrix-header {
  background-color: #88ddff;
  color: rgb(61, 61, 61);

}

.cashflow-matrix-cell {
  background-color: #c8efff;
  color: rgb(44, 44, 44);
  text-align: right !important;
}