.projects {
    tbody {
        tr:hover {
            background-color: #ffffeb;
        }
    }
}
/*
.sidebar-item:hover {
    background-color: #ffffeb;
}
*/
.sidebar-nav ul .sidebar-item .sidebar-link i {
    font-size: 14px !important;
}